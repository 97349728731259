import React from "react";
import "./DividendKeyFigures.css";

function DividendKeyFigures() {
  return (
    <div className="dividend-key-figures-main-container">
      <div
        className="dividend-key-figures-sub-container"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <h3>CIC – Dividend Key Figures</h3>
        <div className="dividend-key-figures-table-container">
          <table>
            <thead>
              <tr>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Year
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  Dividend(Mn)
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Dividend/Share
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  Earning/Share
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  Dividend
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>2022</td>
                <td>852.93</td>
                <td>2.25</td>
                <td>9.72</td>
                <td>4.32</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>2021</td>
                <td>473.85</td>
                <td>1.25</td>
                <td>8.26</td>
                <td>6.61</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>2020</td>
                <td>189.54</td>
                <td>0.50</td>
                <td>2.20</td>
                <td>4.40</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1000" data-aos-once="true">
                <td>2019</td>
                <td>-</td>
                <td>-</td>
                <td>1.28</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1100" data-aos-once="true">
                <td>2018</td>
                <td>94.77</td>
                <td>0.25</td>
                <td>(1.09)</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1200" data-aos-once="true">
                <td>2017</td>
                <td>189.54</td>
                <td>0.50</td>
                <td>1.46</td>
                <td>2.92</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1300" data-aos-once="true">
                <td>2016</td>
                <td>379.08</td>
                <td>1.00</td>
                <td>3.57</td>
                <td>3.57</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1400" data-aos-once="true">
                <td>2015</td>
                <td>284.31</td>
                <td>0.75</td>
                <td>2.01</td>
                <td>68</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1500" data-aos-once="true">
                <td>2014</td>
                <td>-</td>
                <td>-</td>
                <td>(2.56)</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1600" data-aos-once="true">
                <td>2013</td>
                <td>154.48</td>
                <td>0.41</td>
                <td>0.62</td>
                <td>1.51</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DividendKeyFigures;
