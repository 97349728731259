import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Graph1.css";
import Aos from "aos";
import "aos/dist/aos.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SplineChart = () => {
  const [chart1Animation, setChart1Animation] = useState(false);

  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);

  const data1 = {
    labels: [
      "",
      "12/13",
      "13/14",
      "14/15",
      "15/16",
      "16/17",
      "17/18",
      "18/19",
      "19/20",
      "20/21",
      "21/22",
    ],
    datasets: [
      {
        data: [, 0.5, 0, 0.8, 0.9, 0.7, 0.5, 0.3, 0.6, 1.4, 2.5],
        fill: false,
        backgroundColor: "rgba(255, 255, 255)",
        borderColor: "rgba(255, 255, 255)",
        tension: 0.4,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: true },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          stepSize: 1,
          font: { size: 10 },
          callback: (value) => {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5,
    };

    const chart1Observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setChart1Animation(true);
      }
    }, observerOptions);

    if (chart1Ref.current) {
      chart1Observer.observe(chart1Ref.current);
    }

    return () => {
      if (chart1Ref.current) {
        chart1Observer.unobserve(chart1Ref.current);
      }
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="financial-graph-charts-container">
      <div
        className="financial-graph-lineChart"
        ref={chart1Ref}
        data-aos="fade-left"
        data-aos-delay="1100"
        data-aos-duration="1100"
      >
        <div className="financial-graph-voting-chart">
          <div className="financial-graph-chart-container">
            {chart1Animation && <Line data={data1} options={options1} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplineChart;
