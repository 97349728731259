import React, { useEffect } from "react";
import "./financial-year.css";
import Graph from "./Graph1";

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
    <div className="box-box">
      <center>
        <h2 className="fina-topic" data-aos="zoom-in" data-aos-delay="500">
          Last Dividend Payment for the financial year 2021/22
        </h2>
      </center>
      <div className="container-box">
        <div className="left-container-box">
          <div className="app-box red-box-1" data-aos="fade-down">
            <div class="content-box">
              <h2 data-aos="zoom-in" data-aos-delay="1000">
                Dividend Announcements :
              </h2>
              <h3 data-aos="zoom-in" data-aos-delay="1000">
                Final Dividend 2021/22
              </h3>
              <p data-aos="zoom-in" data-aos-delay="1000">
                Dividend Per Share
              </p>
              <div
                class="share-details"
                data-aos="zoom-in"
                data-aos-delay="1000"
              >
                <div class="share-type">
                  <p>Ordinary Shares</p>
                  <p>Non-Voting (Class X)</p>
                </div>
                <div class="share-value">
                  <p>Rs. 1.00</p>
                  <p>Rs. 1.00</p>
                </div>
              </div>
              <div
                class="payment-info"
                data-aos="zoom-in"
                data-aos-delay="1000"
              >
                <p>Payment Date On or Before</p>
                <p>22nd July 2022</p>
              </div>
            </div>
          </div>
          <div className="app-box green-box-2" data-aos="fade-up">
            <div class="content-box">
              <h3 data-aos="zoom-in" data-aos-delay="1000">
                Interim dividend paid for <br />
                The financial year 2021/22
              </h3>
              <p data-aos="zoom-in" data-aos-delay="1000">
                Dividend Per Share
              </p>
              <div
                class="share-details"
                data-aos="zoom-in"
                data-aos-delay="1000"
              >
                <div class="share-type">
                  <p>Ordinary Shares</p>
                  <p>Non-Voting (Class X)</p>
                </div>
                <div class="share-value">
                  <p>Rs. 1.00</p>
                  <p>Rs. 1.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-box blue-box-3" data-aos="fade-left">
          <>
            <h2
              className="content-box-graph1"
              data-aos="zoom-in"
              data-aos-delay="1000"
            >
              CIC – Historic Dividend
            </h2>

            <div class="share-details" data-aos="zoom-in" data-aos-delay="1000">
              <div class="share-type">
                <p>Dividend Per Share</p>
              </div>
              <div class="share-value">
                <p>Rs.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </div>
            </div>
            <Graph />
            <br />
          </>
        </div>
      </div>
    </div>
  );
};

export default App;
