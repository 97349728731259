import React from "react";
import DividendInformationMainBanner from "../templates/InvestorDesktop/DividendInformation/DIMainBanner";
import FinancialYear from "../templates/InvestorDesktop/DividendInformation/financial-year";
import DividendKeyFigures from "../templates/InvestorDesktop/DividendInformation/DividendKeyFigures"

export default function DividendInformation() {
  return (
    <div>
      {/* <Header /> */}
      <DividendInformationMainBanner />
      <FinancialYear />
      <DividendKeyFigures />
      {/* <Footer /> */}
    </div>
  );
}
