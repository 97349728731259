import React from "react";
import "./DIMainBanner.css";
import Image from "../../../images/DIMainBanner.jpeg";

export default function DIMainBanner() {
  return (
    <div>
      <div style={{ position: "relative", marginBottom: "-4px" }}>
        <img className="analystCoverage-home-image" alt="" src={Image} />
        <div className="analystCoverage-image-text-box">
          <div className="analystCoverage-image-background-text">
            <h1 className="analystCoverage-image-topic">
              Dividend Information
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
